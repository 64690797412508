#searchLead{
    font-size: smaller;
}
#leadsearch{
    /* display: flex; */
    align-items: end;
    /* justify-content: end; */
}

.lead-searchBox{
    border-radius: 2rem;
    box-shadow: 1px 1px 3px #ababab;
}

.lead-card{
    border-radius: .8rem;
    background-color: #ffffff;
    box-shadow: 0px 2px 8px #818181;
    transition: all .1s;
    height: 4.8rem;
}
.lead-card:hover{
 transform: scale(1.004);
}

.lead-title-card{
    border-radius: .8rem;
    background-color: #1e4eca;
    box-shadow: 0px 2px 8px #818181;
    transition: all .1s;
    height: 4.8rem;
}


.lead-card-title{
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    /* line-height: 10px; */
    color: #000000;
    letter-spacing: 0.035em;
}
.lead-card-head{
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    /* line-height: 30px; */
    color: #ffffff;
    letter-spacing: 0.035em;
}

.serial-number{
    line-height: 15px;
}

.lead-data{
    margin-bottom: .4rem;
    /* position: relative; */
    /* color: #000000; */
}

.lead-content{
    margin-right: 1rem;
}
.assign-lead{
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    /* line-height: 30px; */
    letter-spacing: 0.025em; 
}

.assign-lead {
    color: #32f302;
}

.assign-lead .Unassigned{
    color: #ffba0a;
}

.lead-status{
    border-radius: 1rem;
    font-style: normal;
    font-weight: 500;
    font-size: 9px;
    /* line-height: 18px; */
    color: #ffffff;
    letter-spacing: 0.035em;
}
.lead-status-container .Closed{
    background-color: #32f302;
    
}
.lead-status-container .Open{
    background-color: #25b4d3;
}
.lead-status-container .Rejected{
    background-color: #d31c1c;
}
.lead-status-container .Unavailable{
    background-color: #dec910;
}
.lead-status-container .Paused{
    background-color: #5437f7c7;
}
.lead-status-container .New{
    color: #32f302;
    border: 1px solid #32f302;
}

.lead-container{
    border-radius: 1rem;
    /* height: 400px; */
    min-height:10px;
    max-height: 900px;
    overflow-x: hidden;
    overflow-y: auto;
}

.lead-plus-button{
    width: 64px;
    height: 64px;
}

.lead-modal{
    border-radius: 4rem;
}
.lead-desc-modal{
    border-radius: 2rem;
    background-color: #F4F4F4;
}
.lead-desc-area{
    border-radius: 1.5rem;
    text-decoration: none;
    resize: none;
    height: 15rem;
    border: none;
}

.next-page {
    font-style: bold;
    font-weight: 1000;
    font-size: 17px;
    color: #ffffff;
    letter-spacing: 0.025em;
}

.lead-dropdown {
    position: absolute;
    z-index: 9999;
}

.status-dropdown{
    width: 7rem !important;
    padding-left: 0.2rem;
    font-weight: 400    ;
    padding-right: 0;
    font-size: 11px;
}
.status-dropdown .menu{
    font-family: 'Poppins', sans-serif !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.025em;
}

.lead-datepicker .MuiInputBase-root{
    height: 3rem;
    /* background-color: red; */
}
  
.field-card{
    max-height: 20rem;
    overflow-y: auto;
    overflow-x: hidden;
}

@media only screen and (max-width: 768px){
    .profile-edit-card{
        right: 3rem !important;
    }
}

.profile-edit-card{
    right: 4rem;
}

