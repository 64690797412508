.App {
  /* text-align: center; */
  /* background: #F8F8F8; */
  height: 100%;
}
body{
  font-family: 'Poppins';
  background-color: #F8F8F8;
}


/* Common css */

.btn-pill{
  border-radius: 2rem;
}

.mouse-pointer{
  cursor: pointer;
}