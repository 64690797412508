.followup-card{
    border-radius: .8rem;
    background-color: #ffffff;
    box-shadow: 0px 2px 8px #818181;
    transition: all .1s;
    height: 4.8rem;
}
.followup-card.responsive{
    height: fit-content!important;
}
.followup-card:hover{
    transform: scale(1.004);
}

.followup-title-card{
    border-radius: .8rem;
    background-color: #1e4eca;
    box-shadow: 0px 2px 8px #818181;
    transition: all .1s;
    height: 4.8rem;
}


.followup-card-title{
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    /* line-height: 10px; */
    color: #818181;
    letter-spacing: 0.035em;
}
.followup-card-head{
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    /* line-height: 30px; */
    color: #ffffff;
    letter-spacing: 0.035em;
}

.followup-data{
    margin-bottom: .4rem;
}

.serial-number{
    line-height: 15px;
}

.followup-data{
    color: #000000;
}
table.followup-data>tr th{
    padding-right: 3rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
}
table.followup-data{
    font-size: 1rem;
}

.followup-content{
    margin-right: 1rem;
}
.assign-followup{
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    letter-spacing: 0.025em; 
}

.assign-followup .Assigned{
    color: #32f302;
}

.assign-followup .Unassigned{
    color: #ffba0a;
}

.followup-status{
    border-radius: 1rem;
    font-style: normal;
    font-weight: 500;
    font-size: 9px;
    color: #ffffff;
    letter-spacing: 0.035em;
}
.followup-status-container .Lead{
    background-color: #32f302;
    
}
.followup-status-container .First{
    background-color: #25b4d3;
}
.followup-status-container .Second{
    background-color: #d31c1c;
}
.followup-status-container .Third{
    background-color: #dec910;
}

.followup-container{
    border-radius: 1rem;
    /* min-height: 700px;
    max-height: 900px;
    overflow-x: visible;
    overflow-y: hidden; */
}

.followup-plus-button{
    width: 64px;
    height: 64px;
}

.followup-modal{
    border-radius: 2rem;
}
.followup-desc-modal{
    border-radius: 2rem;
    background-color: #F4F4F4;
}
.followup-desc-area{
    border-radius: 1.5rem;
    text-decoration: none;
    resize: none;
    height: 15rem;
    border: none;
}

.status-dropdown.ui.dropdown .menu > .item{
    background-color: rgb(255, 255, 255);
    font-size: 12px;
    color: rgb(0, 0, 0);
    letter-spacing: 0.025em;
    width: 7rem!important;

}
.followup-dropdown{
    width: 6rem !important;
    padding-left: 0.2rem;
    font-weight: 400    ;
    padding-right: 0;
    font-size: 11px;
}
.followup-dropdown .menu{
    font-family: 'Poppins', sans-serif !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.025em;
}