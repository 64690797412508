#addLead {
    font-size: small;
    width: fit-content;
}

#leadupload {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.dashboard-card {
    border-radius: 2rem;
    box-shadow: 1px 1px 8px #818181;
    transition: all .1s;
}

.dashboard-card.agent {
    background-color: #1E4ECA;
    ;
}

.dashboard-card.company {
    background-color: #ffffff;
}

.dashboard-card:hover {
    transform: scale(1.05);
}

.dashboard-card-img {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 50%;
    aspect-ratio: 1 / 1;
}

.dashboard-card-img.company {
    background-color: #E4ECFF;

}

.dashboard-card-img.agent {
    background-color: #7895df;

}

.dashboard-card-title.company {
    color: #818181;
    font-weight: 600;
    font-size: 11px;
}

.dashboard-card-title.agent {
    color: #ffffff;
    font-weight: 600;
    font-size: 12px;
}

.dashboard-card-count.company {
    color: #242424;
    font-size: 24px;
    font-weight: 900;

}

.dashboard-card-count.agent {
    color: #ffffff;
    font-size: 24px;
    font-weight: 900;

}

.chart-card {
    border-radius: 2rem;
    background-color: #ffffff;
    /* box-shadow: 1px 1px 8px #818181; */
    transition: all .1s;
}

.upload-modal {
    border-radius: 2rem;
}

.lead-image-upload {
    border: .2rem dashed #C1C1C1;

}

.browse-btn {
    border-radius: 2rem;
}

.uploadPreview {
    height: 100px;
    width: 100px;
    object-fit: cover;
}

.dashboard-chart-sub{
    font-size: 10px;
    color: #929292;
    font-weight: 600;
}

.pie-chart-text-container{
    border-collapse: separate; border-spacing: 10px;
}

.chart-badge1{
    background-color: #7B06C2!important;
}
.chart-badge2{
    background-color: #0037C3!important;
}
.chart-badge3{
    background-color: #CA380A!important;
}
.chart-badge4{
    background-color: #BC007C!important;
}
.chart-badge5{
    background-color: #FFC702!important;
}
.chart-badge-text{
    font-size: 10px;
    font-weight: 400;
}

.lead-template a{
 text-decoration: none;
 color: #ffffff;
}