.customer-table-card {
    border-radius: 1rem;
    max-height: 1200px;
    min-height: 600px;
}
.customer-table td:first-child {
    border-left-style: solid;
    border-top-left-radius: 1.5rem; 
    border-bottom-left-radius: 1.5rem;
}
.customer-table td:last-child {
    border-right-style: solid;
    border-bottom-right-radius: 1.5rem; 
    border-top-right-radius: 1.5rem; 
}

.customer-table{
    border:#ffffff!important;
}

.data-color{
    color: #363636 !important;
}

.table-data{
    font-size: 12px;
    padding-top: 1rem !important;
}

.table-dropdown{
    font-size: 12px;
    padding-top: .5rem !important;
    margin-right: 0px !important;
}

.table-edit{
    font-size: 12px;
    padding-top: .6rem !important;
}

.table-edit .card{
    border-radius: 2.5rem;
    align-items: center;
    width: 4.2rem;
    background-color: #3e66cd;
    color: #eff3fb;
}

.add-position{
    bottom: 25px;
}

.customer-add{
    font-size: 12px;
    padding-top: .6rem !important;
}

.customer-add .card{
    border-radius: 2.5rem;
    align-items: center;
    width: 15rem;
    font-size: 15px;
    background-color: #3e66cd;
    color: #eff3fb;
}

.table-dropdown .status-dropdown{
    border-radius: 0.8rem;
    width: 16rem !important;
    background-color: #f9f9f9;
    box-shadow: 1px 1px 1px #737373;
    color:#000000;
    padding-left: 0.8rem;
    font-weight: 500;
    font-size: 12px;
}
.table-dropdown .status-dropdown .menu{
    border-radius: 1rem;
    font-family: 'Poppins', sans-serif !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.025em;
}

.payment-dropdown{
    border-radius: 0.8rem;
    width: 9rem !important;
    color:#000000;
    padding-left: 0.8rem;
    font-weight: 500;
    font-size: 12px;
}

.table-dropdown .Pending{
    background-color:#FD4545;
}

.table-dropdown .Paid{
    background-color: #67FF42 !important;
}

.table-dropdown .Partially{
    background-color: #FFF14B !important;
}

.payment-dropdown .menu{
    border-radius: 1rem;
    font-family: 'Poppins', sans-serif !important;
    background-color: #f9f9f9 !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.025em;
}


.document-card{
    border-radius: 3rem;

}

.customer-profile-card {
    border-radius: 3rem;
    transition: all .1s;
    background-color: rgb(32, 0, 162); /* For browsers that do not support gradients */
    background-image: linear-gradient(to left, rgb(4, 0, 87) , rgb(60, 90, 222));
    color: #ffffff;

}

.customer-profile-img {
    width: 8rem;
    aspect-ratio: 1/1;
    border-radius: 5rem;
    object-fit: cover;
    background: #dfdfdf;
}

.customer-profile-name {
    font-size: 20px;
    font-weight: 600;
}

.customer-profile-data{
    font-size: 14px;
    font-weight: 400;
}

@media only screen and (min-width: 768px){
    .customer-profile-data {
        position: absolute !important;
    }
}

@media only screen and (max-width: 768px){
    .profile-common {
        margin-left: 0.1rem;
    }
}

@media only screen and (min-width: 768px){
    .profile-common {
        right: 1rem;
    }
}

.customer-icons{
    top: 0;
}

@media only screen and (max-width: 768px){
    .customer-icons{
        margin-right: 0rem;
    }
}

.profile-date{
    bottom: 20px;
}

/* .download{
    text-decoration: none;
    color: #ffffff!important;
} */

.session-datepicker .MuiInputBase-root{
    height: 3rem;
    /* background-color: red; */
}

.session-datepicker{
    padding-top: 4px !important;
}

.interest-dropdown{
    border-radius: 0.8rem;
    width: 22rem !important;
    height: 2.9rem !important;
    background-color: #f9f9f9;
    box-shadow: 1px 1.2px 1.2px #737373;
    color:#000000;
    /* padding-top: 1rem !important; */
    padding-left: 0.8rem;
    font-weight: 500;
    font-size: 14px;
}
.interest-dropdown .menu{
    border-radius: 1rem;
    padding-left: 2rem !important;
    font-family: 'Poppins', sans-serif !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.025em;
}

.add-interest{
    box-shadow: 1px 2px 2px #737373 !important;
    border-radius: 2rem;
}

.nav-link{
    background-color: #ffffff !important;
    color: rgb(4, 0, 87) !important;
}

.nav-item .active{
    background-color: #1f2b97 !important;
    color: #ffffff !important;
}

.add-interest-btn{
    background-color: rgb(4, 0, 87) !important;
    color: #ffffff !important;
}

.table-card{
    max-height: 18.5rem;
    overflow-y: auto;
    overflow-x: auto;
}

.field-data-card{
    max-height: 18.5rem;
    overflow-y: auto;
    overflow-x: hidden;
}

.call-button{
    border-radius: 5rem;
    background-color: #25ab04;
    color: #ffffff;
}
.customer-profile-card>.text-center .btn:hover{
    /* color: rgb(244, 0, 0); */
    background-color: #51ed2a;
    text-decoration: none;
}