.nav-header{
    font-weight: 700;
}
.nav-right{
    
    background-color: white;
    border-radius: 2rem;
    padding: .5rem;
}
.nav-right div{
    display: inline-block;
}

.profile-drop-down{
    border-radius: 1rem!important;
    font-size: 20rem;
    background-color: rgb(255, 255, 255);
    width: 15rem!important;

}
.navbar{
    position:sticky;
    z-index: 999;
    top:0
}
.navbar.lower{
    z-index: 998!important;
}
.profile-img {
    width: 3rem;
    height: 3rem;
    border-radius: 2rem;
    object-fit: cover;
    background: #dfdfdf;
}